import PropTypes from "prop-types";

import styles from "../../../scss/components/general/Banner.module.scss";

const Banner = ({ header, text, styleBanner, image }) => (
  <>
    <div className={`${styles.banner} ${styleBanner ? styleBanner : ""} ${styleBanner && styles[styleBanner]}`}>
      <h3>{header}</h3>
      <span>{text}</span>
    </div>
    <style jsx>{`
      .homeBanner {
        background-image: url("/static/images/${image}");
      }
    `}</style>
  </>
);

Banner.defaultProps = {
  header: "",
  text: "",
  styleBanner: "",
};

Banner.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  styleBanner: PropTypes.string,
};

export default Banner;

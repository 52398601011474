import PropTypes from "prop-types";
import Button from "../UI/Button";
import constants from "../../constants/staticFile";
import styles from "../../../scss/components/general/CardNewspapers.module.scss";
import { GA_ACTIONS, GA_CATEGORIES } from "../../constants/gaConstants";

const CardNewspapers = ({
  header,
  img,
  info,
  btnName,
  createPub,
  hideBtn,
  listView,
  handleGroup,
  checkCard,
  publicationId,
  id,
  pricing,
}) => (
  <div
    className={`${styles.container} ${!listView && styles.compactCards} ${checkCard && styles.active}`}
    role="button"
    tabIndex={0}
    onKeyPress={() => {}}
    onClick={() => handleGroup(publicationId)}
  >
    <div className={styles.contentBox}>
      <img src={img !== null && img.src !== "" ? img.src : constants.imageUndefined} alt={header} />
      <Button
        color={hideBtn ? "hide" : "buttonCard"}
        onClick={createPub(id)}
        analytics
        category={GA_CATEGORIES.PLACE_ORDER}
        action={GA_ACTIONS.SELECT_PUBLICATION}
        label={header}
      >
        {btnName}
      </Button>
    </div>
    {pricing && (
      <span style={{ color: "red", fontSize: "22px" }} className={`${listView}`}>{`Starting at ${pricing}`}</span>
    )}
    <span className={`${!listView ? styles.hideMain : ""}`}>{info}</span>
    <div className={styles.btnCardMobile}>
      <Button color={hideBtn ? "hide" : "buttonCardMobile"} onClick={createPub(id)} id={id}>
        {btnName}
      </Button>
    </div>
  </div>
);

CardNewspapers.defaultProps = {
  header: "",
  img: {},
  info: "",
  btnName: "",
  hideBtn: false,
  createPub: () => () => {},
  handleGroup: () => {},
  listView: true,
  checkCard: false,
  id: "",
};

CardNewspapers.propTypes = {
  header: PropTypes.string,
  img: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  info: PropTypes.string,
  btnName: PropTypes.string,
  id: PropTypes.string,
  createPub: PropTypes.func,
  handleGroup: PropTypes.func,
  hideBtn: PropTypes.bool,
  listView: PropTypes.bool,
  checkCard: PropTypes.bool,
  publicationId: PropTypes.string.isRequired,
};

export default CardNewspapers;
